<template>
<div>
    <div class="advertise"  style="padding-top:0px">
        <a-layout>
            <a-layout-content>
                <a-row class="s-layout">
                    <a-col :span="24">
                        <div class="s-advertise-font1">
                            광고주 관리
                        </div>
                        <div style="margin-top:30px">
                            <a-tabs v-model="activeTabName">
                                <a-tab-pane tab="계정관리" key="first">
                                    <div class="s-advertise-bar1" style="  height: 168px;">
                                        <div style="padding-top:30px;padding-left:20px">
                                            <div class="s-advertise-font3">
                                                초대 URL을 통해 스토어링크에 가입한 광고주는 자동으로 광고주 리스트에 추가가 됩니다.<br />
                                                대행사 자사 사이트 등을 통해 홍보할 때 해당 URL을 활용해 보세요
                                            </div>
                                            <div>
                                                <a-input-search v-model="url" style="width:300px;margin-top:20px;color:black">
                                                    <a-button slot="enterButton" style="color:white;background-color:#0264fb" v-clipboard:copy="url">
                                                        <i class="fal fa-copy">&nbsp;&nbsp;</i>복사하기
                                                    </a-button>
                                                </a-input-search>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="s-advertise-bar1" style="margin-top:20px;">
                                        <div style="padding:10px">
                                            <div class="s-advertise-font2" style="margin-bottom:16px">키워드 분석</div>
                                            <a-table :columns="columns" :data-source="tableData" :pagination="false" :loading="loading" >
                                            </a-table>
                                        </div>
                                    </div>
                                </a-tab-pane>
                                <a-tab-pane tab="정산관리" key="second">
                                   <div class="s-advertise-bar1" style="margin-top:20px;">
                                        <div style="padding:10px">
                                          <div class="s-advertise-font2" style="margin-bottom:16px">정산 내역</div>
                                          <a-table :columns="columns2" :data-source="tableData2" :pagination="false" :loading="loading" :scroll="{ x: 1800}">
                                            <span slot="status" slot-scope="text, record">
                                              <a @click="onViewReport(record.key)">{{ text }}</a>
                                            </span>
                                          </a-table>
                                        </div>
                                   </div>
                                </a-tab-pane>
                            </a-tabs>
                        </div>
                    </a-col>
                </a-row>
            </a-layout-content>
        </a-layout>
    </div>
    <nav-footer></nav-footer>
</div>
</template>

<script>
Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

String.prototype.trims = function() {
  return this.replace(/^\s+|\s+$/g, "");
};

import SubMenu from "../../layouts/nav/SubMenu";
import NavFooter from "../../layouts/nav/NavFooter";
import firebase from "firebase";
import {
  getCurrentTimeNew,
  getCurrentTime2,
  getHex,
  replaceAll,
  showError,
  showSuccess
} from "../components/fnc.js";

export default {
  data() {
    return {
      url: "",
      seq: 1,
      dialogVisible: false,
      dialogVisible2: false,
      storename: "",
      loading: false,
      loaded: false,
      tableData: [],
      tableData2: [],
      activeTabName: "first",
      columns: [
        {
          title: "연번",
          dataIndex: "no",
          key: "no",
          width: 100
        },
        {
          title: "계정",
          dataIndex: "email",
          key: "email",
          width: 350
        },
        {
          title: "가입일",
          dataIndex: "loggindttm",
          key: "loggindttm",
          width: 250
        },
        {
          title: "유입방법",
          dataIndex: "type",
          key: "type",
          width: 100,
          title: () => {
            return (
              <a-tooltip placement="topLeft">
                <template slot="title">
                  <span>
                    URL : 대행사 URL을 통해 가입한 광고주<br />
                    직접지정 : 대행사 요청에 의해 수동으로 연결된 광고주<br />
                    본인계정 : 현재 로그인 되어 있는 계정으로 대행사가 직접
                    관리하는 캠페인에 대한 정산을 위해 표기
                  </span>
                </template>
                <div style="text-align:center">
                  <span>
                    유입방법&nbsp;<i class="fal fa-question-circle" />
                  </span>
                </div>
              </a-tooltip>
            );
          }
        },
        {
          title: "상호명",
          dataIndex: "store",
          key: "store"
        }
      ],
      columns2: [
        {
          title: "연번",
          dataIndex: "no",
          key: "no",
          width: "70px"
        },
        {
          title: "계정",
          dataIndex: "email",
          key: "email",
          width: "250px"
        },
        {
          title: "상호명",
          dataIndex: "store",
          key: "store"
        },
        {
          title: "진행일",
          dataIndex: "date",
          key: "date",
          width: "200px"
        },
        {
          title: "진행상태",
          dataIndex: "status",
          key: "status",
          scopedSlots: { customRender: "status" },
          width: "150px"
        },
        {
          dataIndex: "kind",
          key: "kind",
          width: "120px",
          title: () => {
            return (
              <a-tooltip placement="topLeft">
                <template slot="title">
                  <span>
                    대행사 : 최초 입금을 대행사에서 받았다면 대행사는
                    스토어링크에 [제품비용+스토어링크 배분액]을 입금합니다<br />
                    스토어링크 : 최초 입금을 스토어링크에서 받았다면
                    스토어링크는 대행사에 [대행사 배분액]을 정산합니다.
                  </span>
                </template>
                <div style="text-align:center">
                  <span>
                    최초입금&nbsp;<i class="fal fa-question-circle" />
                  </span>
                </div>
              </a-tooltip>
            );
          }
        },
        {
          dataIndex: "input",
          key: "input",
          width: "120px",
          title: () => {
            return (
              <a-tooltip placement="topLeft">
                <template slot="title">
                  <span>
                    최초 입금의 주체에 따라 입금받아야 할 비용이 달라집니다.<br />
                    최초 입금의 주체가 대행사일 경우 [제품비용+스토어링크
                    배분액]을 입금 받습니다.<br />
                    최초 입금의 주체가 스토어링크일 경우 [제품비용+진행비용]
                    모두를 입금 받은 후 대행사 배분액을 정산합니다.
                  </span>
                </template>
                <div style="text-align:center">
                  <span>
                    입금비용&nbsp;<i class="fal fa-question-circle" />
                  </span>
                </div>
              </a-tooltip>
            );
          }
        },
        {
          dataIndex: "result",
          key: "result",
          width: "120px",
          title: () => {
            return (
              <a-tooltip placement="topLeft">
                <template slot="title">
                  <span>
                    최초 입금의 주체에 따라 정산해야 할 비용이 달라집니다.<br />
                    최초 입금의 주체가 대행사일 경우 정산비용은 없습니다.
                    대행사에서 정산 받을 비용을 제외하고 스토어링크에 보내기
                    때문입니다.<br />
                    최초 입금의 주체가 스토어링크일 경우 [대행사 배분액]을
                    스토어링크가 대행사에게 정산합니다.
                  </span>
                </template>
                <div style="text-align:center">
                  <span>
                    정산비용&nbsp;<i class="fal fa-question-circle" />
                  </span>
                </div>
              </a-tooltip>
            );
          }
        },
        {
          dataIndex: "flag",
          key: "flag",
          width: "120px",
          title: () => {
            return (
              <a-tooltip placement="topLeft">
                <template slot="title">
                  <span>
                    정산여부는 입금완료와 정산완료로 나뉩니다.<br />
                    입금완료의 경우 스토어링크에 입금된 상태로 최초입금이
                    대행사일 경우 대행사가 스토어링크에 입금한 상태, 최초입금이
                    스토어링크일 경우 광고주가 스토어링크에 입금한 상태를
                    말합니다.<br />
                    정산완료의 경우 대행사와의 모든 정산이 완료된 상태로
                    최초입금이 대행사일 경우 별도의 정산 과정 없이 입금 후
                    정산완료가 되고, 최초입금이 스토어링크일 경우 스토어링가
                    대행사에 [대행사 배분액]을 입금해야 정산완료가 됩니다.
                  </span>
                </template>
                <div style="text-align:center">
                  <span>
                    정산여부&nbsp;<i class="fal fa-question-circle" />
                  </span>
                </div>
              </a-tooltip>
            );
          }
        },
        {
          dataIndex: "product",
          key: "product",
          width: "120px",
          title: () => {
            return (
              <a-tooltip placement="topLeft">
                <template slot="title">
                  <span>진행인원수 X 제품비용입니다.</span>
                </template>
                <div style="text-align:center">
                  <span>
                    제품비용&nbsp;<i class="fal fa-question-circle" />
                  </span>
                </div>
              </a-tooltip>
            );
          }
        },
        {
          dataIndex: "c1",
          key: "c1",
          width: "120px",
          title: () => {
            return (
              <a-tooltip placement="topLeft">
                <template slot="title">
                  <span>
                    진행인원수 X 진행비용으로 [스토어링크 배분액 + 대행사
                    배분액]과 동일합니다.<br />
                    미션에 따라, 대행사의 책정에 따라 진행인원수 1명당
                    진행비용은 달라질 수 있습니다.
                  </span>
                </template>
                <div style="text-align:center">
                  <span>
                    진행비용&nbsp;<i class="fal fa-question-circle" />
                  </span>
                </div>
              </a-tooltip>
            );
          }
        },
        {
          dataIndex: "storelink",
          key: "storelink",
          width: "150px",
          title: () => {
            return (
              <a-tooltip placement="topLeft">
                <template slot="title">
                  <span>
                    진행비용 중 스토어링크의 몫으로 진행인원 1명당 4,000원으로
                    계산합니다.
                  </span>
                </template>
                <div style="text-align:center">
                  <span>
                    스토어링크배분액&nbsp;<i class="fal fa-question-circle" />
                  </span>
                </div>
              </a-tooltip>
            );
          }
        },
        {
          dataIndex: "agency",
          key: "agency",
          width: "120px",
          title: () => {
            return (
              <a-tooltip placement="topLeft">
                <template slot="title">
                  <span>
                    진행비용 중 대행사의 몫으로 진행인원 1명당 대행사가 설정한
                    진행비용 중 스토어링크 배분액 4,000원을 제외하고 계산합니다.<br />
                    (예)대행사가 진행인원 1명당 진행비용을 20,000원으로
                    책정했다면 대행사 배분액은 20,000원 – 4,000원인 16,000원이
                    됩니다.
                  </span>
                </template>
                <div style="text-align:center">
                  <span>
                    대행사배분액&nbsp;<i class="fal fa-question-circle" />
                  </span>
                </div>
              </a-tooltip>
            );
          }
        }
      ]
    };
  },
  components: {
    SubMenu,
    NavFooter
  },
  computed: {
    getHeight() {
      return window.innerHeight - 300;
    },
    getName() {
      return this.$store.state.AppActiveUser.displayName;
    },
    getUid() {
      return this.$store.state.AppActiveUser.uid;
    },
    getEmail() {
      return this.$store.state.AppActiveUser.email;
    },
    getUserKey() {
      return this.$store.state.AppActiveUser.key;
    },
    getCreatedAt() {
      return this.$store.state.AppActiveUser.createdAt;
    },
    getUrl() {
      return this.$store.state.AppActiveUser.url;
    }
  },
  mounted() {
    this.getBitly();
    this.onLoadAdvertiser();
    this.onLoadCampaignHistory();
    fbq("track", "PageView");
  },
  methods: {
    onViewReport(key) {
      let routeData = this.$router.resolve({
        name: "report",
        query: {
          cid: key
        }
      });
      window.open(routeData.href, "_blank");
    },
    getBitly() {
      // init();
      var self = this;
      if (this.getUrl == "") {
        this.$http
          .post(
            "https://us-central1-store-link-49fa1.cloudfunctions.net/getShortURL",
            {
              url: "https://storelink.io/#/invitation?uid=" + self.getUid
            }
          )
          .then(function(x) {
            if (x.data.status_code == "200") {
              // console.log(x.data.data);
              if (x.data.data.url) {
                self.url = x.data.data.url;

                if (self.getUserKey) {
                  var uref = firebase.database().ref("user/" + self.getUserKey);

                  var upData = {
                    url: self.url
                  };

                  uref
                    .update(upData)
                    .then(function() {})
                    .catch(function(error) {
                      alert("Data could not be saved." + error);
                    });
                }
              }
            }
          });
      } else {
        this.url = this.getUrl;
      }
    },
    onLoadAdvertiser() {
      this.tableData = [];
      var self = this;

      var idx = 1;
      var _t = {
        no: idx,
        email: this.getEmail,
        loggindttm: getCurrentTimeNew(new Date(this.getCreatedAt)),
        type: "본인계정",
        store: "",
        count: ""
      };

      this.tableData.push(_t);
      idx++;

      var sref = firebase.database().ref("user/");

      sref
        .orderByChild("agency")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            _t = {
              no: idx,
              email: sdatas.val().email,
              loggindttm: getCurrentTimeNew(new Date(sdatas.val().eventdttm)),
              type: "URL",
              store: sdatas.val().companyname ? sdatas.val().companyname : "",
              count: "",
              uid: sdatas.val().uid
            };

            self.tableData.push(_t);
            idx++;
          });
        });
    },
    onLoadCampaignHistory() {
      var self = this;
      this.tableData2 = [];

      var sref = firebase.database().ref("rank_apply/");
      var idx = 1;
      var _t = {};
      sref
        .orderByChild("agency")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            if (sdatas.val().aflag) {
              _t = {
                key: sdatas.key,
                no: idx,
                email: sdatas.val().aacount,
                date: sdatas.val().adate,
                store: sdatas.val().astore,
                status: sdatas.val().astatus,
                kind: sdatas.val().atype,
                input: sdatas.val().ainput,
                result: sdatas.val().aresult,
                flag: sdatas.val().aflag,
                product: sdatas.val().aproduct,
                c1: sdatas.val().ac1,
                storelink: sdatas.val().astorelink,
                agency: sdatas.val().aagency
              };

              self.tableData2.push(_t);
              idx++;
            }
          });
        });
    }
  }
};
</script>

<style>
.s-advertise-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.25px;
  color: #000000;
}

.s-advertise-font2 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.59px;
  color: #000000;
}

.s-advertise-font3 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.59px;
  color: #7d7d7d;
}

.s-advertise-bar1 {
  border-radius: 12px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
}

.advertise .ant-tabs-tab {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #7d7d7d;
}

.advertise .ant-tabs-tab:hover {
  color: #0264fb !important;
}

.advertise .ant-tabs-tab-active {
  color: #0264fb !important;
}

.advertise .ant-tabs-ink-bar {
  background-color: #0264fb !important;
  height: 4px !important;
}
</style>
