<template>
<div class="submenu">
    <a-page-header>
        <template slot="extra">
            <div>
                <a-menu v-model="current" mode="horizontal">
                    <a-menu-item key="home" class="s-s-font1"  v-bind:class="['', menu==1 ? activeClass : '']" @click="$router.push('/pages/dashboard').catch(() => {})">
                        <div> <i class="far fa-home-alt"></i>&nbsp;홈</div>
                        <div class="s-s-ud"  v-bind:class="['', menu==1 ? activeClass : '']"> </div>
                    </a-menu-item>
                    <a-menu-item key="rank" class="s-s-font1"  v-bind:class="['', menu==2 ? activeClass : '']"  @click="$router.push('/pages/overview').catch(() => {})">
                        <div><i class="far fa-search"></i>&nbsp;실시간 검색</div>
                        <div class="s-s-ud "  v-bind:class="['', menu==2 ? activeClass : '']"> </div>
                    </a-menu-item>
                    <a-menu-item key="overview" class="s-s-font1"  v-bind:class="['', menu==3 ? activeClass : '']"  @click="$router.push('/pages/favorite').catch(() => {})">
                        <div> <i class="far fa-star"></i>&nbsp; 제품 관리 </div>
                        <div class="s-s-ud " v-bind:class="['', menu==3 ? activeClass : '']"> </div>
                    </a-menu-item>
                    <a-menu-item key="favorite" class="s-s-font1"  v-if="getIsAgency=='T'" v-bind:class="['', menu==4 ? activeClass : '']" @click="$router.push('/pages/advertise').catch(() => {})">
                        <div> <i class="fas fa-user-tie"></i>&nbsp; 광고주 관리</div>
                        <div class="s-s-ud " v-bind:class="['', menu==4 ? activeClass : '']"> </div>
                    </a-menu-item>
                </a-menu>
            </div>

        </template>

    </a-page-header>
</div>
</template>

<script>
import firebase from "firebase";
import auth from "@/auth/authService";
import { chkLicense } from "../../views/components/fnc.js";
export default {
  props: {
    menu: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      activeClass: "active",
      current: "",
      allowAnalytics: false
    };
  },
  computed: {
    getName() {
      return this.$store.state.AppActiveUser.displayName;
    },
    getUid() {
      return this.$store.state.AppActiveUser.uid;
    },
    getIncludeKeyword() {
      return this.$store.state.AppActiveUser.includekeyword;
    },
    getIsAgency() {
      return this.$store.state.AppActiveUser.isagency;
    },
    getIsAnalytics() {
      return this.$store.state.AppActiveUser.isanalytics;
    }
  },
  mounted() {
    if (chkLicense(null)) {
      this.allowAnalytics = true;
    }
  },
  methods: {
    onBack() {
      this.$router.push("/").catch(() => {});
    }
  }
};
</script>

<style>
.submenu .ant-page-header {
  padding-top: 22px !important;
  padding-bottom: 12px !important;
  height: 82px !important;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  position: fixed;
  width: 100%;
  z-index: 10;
}

.submenu .ant-page-header-heading {
  margin-top: 0px !important;
}

.submenu .ant-menu {
  background-color: white !important;
}

/* .submenu .ant-menu-item {
  color: black !important;
} */

.s-s-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  color: #7d7d7d !important;
}

.s-s-font1.active {
  color: #0264fb !important;
}
.s-s-font1:hover {
  color: #0264fb !important;
}

.s-s-ud.active {
  width: 100%;
  height: 2px;
  margin-top: 10px;
  background: #0464fb;
  border: 2px solid #0464fb;
}

.s-s-ud {
  width: 100%;
  height: 2px;
  margin-top: 10px;
  border: 2px solid white;
}
</style>
